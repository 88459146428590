import React, { FC } from 'react';

const IconComponent = ({
  name,
  icons,
  iconProps,
}: {
  name: string | number;
  icons: Record<string, FC<any>>;
  iconProps: any;
}) => {
  const Icon = icons[name] ?? Object.values(icons)[name as number];
  return <Icon {...iconProps} />;
};

export { IconComponent };
