import React, { FC } from 'react';

interface IProps {
  width?: number;
  height?: number;
  color: string;
}

const AndroidIcon: FC<IProps> = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 34 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1756 39.999C22.513 39.999 23.6793 38.8327 23.6793 37.4953V31.648H25.3511C26.3543 31.648 27.0229 30.9791 27.0229 29.9762V13.2754H6.97729V29.9763C6.97729 30.9794 7.64601 31.6481 8.64908 31.6481H10.321V37.4954C10.321 38.8329 11.4871 39.9992 12.8248 39.9992C14.162 39.9992 15.3282 38.8329 15.3282 37.4954V31.6481H18.6719V37.4954C18.6718 38.8327 19.8381 39.999 21.1756 39.999Z"
      fill={color}
    />
    <path
      d="M31.1986 29.9763C32.536 29.9763 33.7021 28.8101 33.7021 27.4726V15.7781C33.7021 14.4477 32.536 13.2754 31.1986 13.2754C29.861 13.2754 28.6948 14.4477 28.6948 15.7781V27.4726C28.6948 28.81 29.8609 29.9763 31.1986 29.9763Z"
      fill={color}
    />
    <path
      d="M2.80182 29.9763C4.13938 29.9763 5.30556 28.8101 5.30556 27.4726V15.7781C5.30556 14.4477 4.13952 13.2754 2.80182 13.2754C1.46439 13.2754 0.29834 14.4477 0.29834 15.7781V27.4726C0.29834 28.81 1.46439 29.9763 2.80182 29.9763Z"
      fill={color}
    />
    <path
      d="M25.0166 0.25227C24.6824 -0.08409 24.1848 -0.08409 23.8505 0.25227L21.6083 2.48706L21.5048 2.59037C20.1753 1.92459 18.6846 1.5921 17.0246 1.5889C17.0164 1.5889 17.0084 1.58863 17.0003 1.58863H17C16.9916 1.58863 16.9839 1.5889 16.9755 1.5889C15.3155 1.5921 13.8249 1.92459 12.4955 2.59037L12.3918 2.48706L10.1496 0.25227C9.81513 -0.08409 9.3178 -0.08409 8.98357 0.25227C8.64908 0.586761 8.64908 1.08316 8.98357 1.41738L11.1526 3.58677C10.4537 4.0534 9.81994 4.62442 9.26881 5.27751C7.9494 6.84145 7.10783 8.87683 6.99211 11.0821C6.99104 11.1051 6.98877 11.1278 6.98771 11.1507C6.98063 11.3009 6.97729 11.4519 6.97729 11.6033H27.0229C27.0229 11.4519 27.0193 11.3009 27.0125 11.1507C27.0114 11.1278 27.0091 11.1051 27.0078 11.0821C26.8923 8.87683 26.0505 6.84132 24.7311 5.27765C24.1802 4.62455 23.5462 4.05354 22.8473 3.58691L25.0166 1.41752C25.3511 1.08316 25.3511 0.586761 25.0166 0.25227ZM12.8215 8.68436C12.1299 8.68436 11.5691 8.12363 11.5691 7.43195C11.5691 6.74028 12.1299 6.17955 12.8215 6.17955C13.5132 6.17955 14.074 6.74028 14.074 7.43195C14.074 8.12363 13.5132 8.68436 12.8215 8.68436ZM21.1786 8.68436C20.487 8.68436 19.9262 8.12363 19.9262 7.43195C19.9262 6.74028 20.487 6.17955 21.1786 6.17955C21.8703 6.17955 22.431 6.74028 22.431 7.43195C22.431 8.12363 21.8703 8.68436 21.1786 8.68436Z"
      fill={color}
    />
  </svg>
);

export { AndroidIcon };
