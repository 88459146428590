/* eslint-disable camelcase */
/**
 * Determines if the color is dark or light
 * @param color
 */
export const isTooDark = (color: string): boolean => {
  const hex = color.replace('#', '');
  if (hex?.length > 6) return true;

  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness < 155;
};
