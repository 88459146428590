/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ICaseQueryEdge } from '../../../../queries/cases/types';
import { usePathPrefix } from '../../../../constants/hooks';
import { TLink } from '../../../../i18n/TLink';
import { IconComponent } from '../../../common/Icon';
import { AndroidIcon } from '../../../icons/AndroidIcon';
import { AppleIcon } from '../../../icons/AppleIcon';
import { WebIcon } from '../../../icons/WebIcon';
import useScreen from '../../../../utils/hooks/useScreen';
import { changePath } from '../../../../utils/hooks';
import { isTooDark } from '../../../../utils/color';

const platformIcons: Record<string, FC<any>> = {
  android: AndroidIcon,
  ios: AppleIcon,
  web: WebIcon,
};

interface IProps {
  item: ICaseQueryEdge['node'];
  appearance?: string;
  from?: string;
  isReversed?: boolean;
}

const BigCaseCell: FC<IProps> = ({
  item,
  appearance = '',
  from,
  isReversed,
}) => {
  const {
    frontmatter: { previewImage },
  } = item;

  const prefix = usePathPrefix();
  const { isDesktopSmall } = useScreen();
  const background = previewImage.background?.startsWith('#')
    ? previewImage.background
    : `url('${previewImage.background}')`;
  const appearanceOptions = appearance.split(' ').map((cls) => styles[cls]);
  const link = changePath(item.fileAbsolutePath, '/cases/projects/');

  const isColorDark = isTooDark(previewImage.background);
  // TODO: Need added color props if added custom color on template
  const fontColor = isColorDark ? '#ffffff' : '#272727';

  const big = previewImage.type === 'big';
  const { platforms } = previewImage;

  return (
    <div
      className={classNames(
        styles.cell,
        {
          [styles.big]: big,
          [styles.reversed]: isReversed,
        },
        appearanceOptions
      )}
      style={{
        background,
      }}
    >
      <div className={styles.cell__images}>
        {previewImage.photos.map((el, i) => (
          <img
            src={`${prefix}${el}`}
            alt="picture"
            key={i}
            style={{
              width: isDesktopSmall
                ? `calc(100% / ${previewImage.photos.length})`
                : '',
            }}
          />
        ))}
      </div>

      <div>
        <div className={styles.header}>
          <div>
            <h3 style={{ color: fontColor }}>{previewImage.title}</h3>
            <p style={{ color: fontColor }}>{previewImage.year}</p>
          </div>
          <div>
            {platforms &&
              platforms.map((el, i) => (
                <span key={i} className={styles.header__icon}>
                  <IconComponent
                    name={el}
                    icons={platformIcons}
                    iconProps={{ color: fontColor, width: 24, height: 24 }}
                  />
                </span>
              ))}
          </div>
        </div>

        <p style={{ color: fontColor }}>{previewImage.description}</p>
      </div>

      {link && <TLink to={link} className={styles.link} params={{ from }} />}
    </div>
  );
};

export { BigCaseCell };
