import React, { FC } from 'react';

interface IProps {
  width?: number;
  height?: number;
  color: string;
}

const WebIcon: FC<IProps> = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M7.37213 11.25H16.6279C16.7594 11.25 16.8657 11.1416 16.8623 11.0101C16.8343 9.92518 16.7608 8.88873 16.649 7.90872C16.6329 7.76772 16.4968 7.67247 16.3592 7.70748C14.9442 8.068 13.4859 8.25001 12 8.25001C10.5141 8.25001 9.05583 8.068 7.64077 7.70748C7.50329 7.67247 7.36707 7.76772 7.35099 7.90872C7.23919 8.88877 7.16569 9.92522 7.13771 11.0101C7.13433 11.1416 7.2406 11.25 7.37213 11.25Z"
        fill={color}
      />
      <path
        d="M7.35099 16.0914C7.36707 16.2324 7.50324 16.3276 7.64077 16.2926C9.05583 15.932 10.5141 15.75 12 15.75C13.4859 15.75 14.9442 15.932 16.3592 16.2925C16.4967 16.3276 16.6329 16.2323 16.649 16.0913C16.7608 15.1112 16.8343 14.0748 16.8623 12.9899C16.8657 12.8583 16.7595 12.75 16.6279 12.75H7.37218C7.24065 12.75 7.13438 12.8584 7.13776 12.9899C7.16569 14.0748 7.23924 15.1113 7.35099 16.0914Z"
        fill={color}
      />
      <path
        d="M7.64072 18.0694C8.06072 20.3932 8.70445 22.299 9.44742 23.6458C9.48052 23.7058 9.53855 23.7481 9.60572 23.7617C10.3855 23.9188 11.1864 24 12 24C12.8136 24 13.6145 23.9188 14.3943 23.7616C14.4615 23.7481 14.5195 23.7058 14.5526 23.6458C15.2956 22.299 15.9393 20.3932 16.3593 18.0693C16.3808 17.9504 16.3076 17.8338 16.1909 17.8024C14.854 17.4423 13.4491 17.25 12 17.25C10.551 17.25 9.14597 17.4423 7.80909 17.8024C7.69237 17.8338 7.6192 17.9504 7.64072 18.0694Z"
        fill={color}
      />
      <path
        d="M7.01207 22.9204C7.20303 23.0077 7.40202 22.817 7.3265 22.6211C7.32519 22.6176 7.32383 22.6141 7.32252 22.6107C6.86633 21.4245 6.49574 20.078 6.21843 18.6217C6.19072 18.4761 6.03575 18.3932 5.89869 18.4495C4.9761 18.8281 4.09621 19.29 3.26817 19.8255C3.14554 19.9048 3.12351 20.0758 3.22298 20.1826C3.31823 20.285 3.4154 20.3859 3.51477 20.4853C4.54373 21.5143 5.72699 22.3328 7.01207 22.9204Z"
        fill={color}
      />
      <path
        d="M5.40318 12.75H0.275903C0.138465 12.75 0.0307946 12.8677 0.0420914 13.0047C0.207794 15.0165 0.869856 16.9266 1.96921 18.592C2.04112 18.701 2.18718 18.7305 2.29612 18.6586C3.19176 18.0669 4.14116 17.5576 5.13932 17.1354C5.34407 17.0488 5.55004 16.9663 5.75713 16.8878C5.85876 16.8493 5.92087 16.747 5.90732 16.6392C5.75976 15.4627 5.66835 14.2354 5.63713 12.9791C5.63399 12.8518 5.53049 12.75 5.40318 12.75Z"
        fill={color}
      />
      <path
        d="M16.3593 5.93062C15.9393 3.6068 15.2956 1.701 14.5526 0.354187C14.5195 0.294188 14.4615 0.25186 14.3943 0.238313C13.6145 0.0811882 12.8136 0 12 0C11.1864 0 10.3855 0.0811872 9.60572 0.238359C9.53855 0.251906 9.48052 0.294235 9.44742 0.354235C8.70445 1.701 8.06072 3.6068 7.64072 5.93067C7.6192 6.04964 7.69237 6.16617 7.80909 6.19763C9.14597 6.55772 10.551 6.75 12 6.75C13.4491 6.75 14.854 6.55772 16.1909 6.19758C16.3076 6.16617 16.3808 6.04959 16.3593 5.93062Z"
        fill={color}
      />
      <path
        d="M18.8607 6.8646C18.6559 6.95123 18.45 7.03368 18.2429 7.11219C18.1412 7.15073 18.0791 7.25296 18.0927 7.36082C18.2402 8.53733 18.3316 9.76461 18.3629 11.0209C18.3661 11.1482 18.4695 11.2499 18.5968 11.2499H23.7241C23.8615 11.2499 23.9692 11.1322 23.9579 10.9953C23.7922 8.98344 23.1301 7.07333 22.0308 5.40792C21.9589 5.29898 21.8128 5.26945 21.7039 5.3414C20.8082 5.93315 19.8588 6.44245 18.8607 6.8646Z"
        fill={color}
      />
      <path
        d="M3.26814 4.17443C4.09618 4.70993 4.97608 5.17193 5.89867 5.5505C6.03574 5.60674 6.1907 5.52382 6.21841 5.37828C6.49572 3.92201 6.86627 2.57544 7.3225 1.38931C7.32382 1.38585 7.32518 1.38238 7.32649 1.37891C7.402 1.18297 7.20302 0.992284 7.01205 1.07961C5.72697 1.66714 4.5437 2.48563 3.5147 3.51467C3.41532 3.61405 3.31815 3.71501 3.2229 3.81734C3.12348 3.92422 3.14556 4.09517 3.26814 4.17443Z"
        fill={color}
      />
      <path
        d="M5.63713 11.021C5.66835 9.76468 5.7598 8.53736 5.90732 7.36089C5.92087 7.25303 5.85876 7.15079 5.75713 7.11226C5.55004 7.03374 5.34407 6.95129 5.13932 6.86467C4.14116 6.44246 3.19176 5.93316 2.29612 5.34151C2.18718 5.26955 2.04117 5.29909 1.96921 5.40802C0.869903 7.0734 0.207794 8.98351 0.0420914 10.9954C0.0307946 11.1324 0.138465 11.2501 0.275903 11.2501H5.40318C5.53049 11.25 5.63399 11.1482 5.63713 11.021Z"
        fill={color}
      />
      <path
        d="M20.7319 19.8255C19.9038 19.29 19.0239 18.828 18.1013 18.4495C17.9643 18.3932 17.8093 18.4761 17.7816 18.6217C17.5043 20.078 17.1338 21.4245 16.6775 22.6107C16.6762 22.6141 16.6748 22.6176 16.6735 22.6211C16.598 22.817 16.797 23.0077 16.988 22.9204C18.273 22.3328 19.4563 21.5143 20.4853 20.4853C20.5847 20.3859 20.6819 20.285 20.7771 20.1826C20.8765 20.0758 20.8545 19.9048 20.7319 19.8255Z"
        fill={color}
      />
      <path
        d="M18.3679 12.75C18.3425 14.0861 18.2491 15.3912 18.0927 16.6391C18.0791 16.747 18.1412 16.8492 18.2429 16.8878C18.45 16.9663 18.6559 17.0487 18.8607 17.1354C19.8588 17.5576 20.8082 18.0669 21.7039 18.6585C21.8128 18.7305 21.9588 18.7009 22.0308 18.592C23.1301 16.9266 23.7922 15.0165 23.9579 13.0046C23.9692 12.8677 23.8615 12.75 23.7241 12.75H18.3679Z"
        fill={color}
      />
      <path
        d="M16.9879 1.07967C16.797 0.992344 16.598 1.18303 16.6735 1.37897C16.6748 1.38244 16.6762 1.38591 16.6775 1.38938C17.1337 2.5755 17.5043 3.92208 17.7816 5.37834C17.8093 5.52388 17.9642 5.60681 18.1013 5.55056C19.0239 5.17195 19.9038 4.71 20.7318 4.1745C20.8545 4.09523 20.8765 3.92428 20.777 3.8174C20.6818 3.71507 20.5846 3.61411 20.4852 3.51473C19.4563 2.48569 18.273 1.6672 16.9879 1.07967Z"
        fill={color}
      />
    </g>
  </svg>
);

export { WebIcon };
